import { PayloadAction } from '@reduxjs/toolkit';
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import BatchActions, { IBatchDownloadTemplateCsv, IBatchExecute, IBatchUpdate } from 'state/actions/batch/BatchActions';
import { selectConfiguration } from 'state/reducers/configuration';
import { getCurrentJWT } from 'state/sagas/auth/auth';

function* listBatches(action: PayloadAction) {
  yield call(listTemplates, action);
  const configuration = yield select(selectConfiguration);

  const res = yield fetch(configuration.API_BASE + `/api/batch`, {
    headers: {
      Authorization: yield getCurrentJWT()
    }
  });

  yield put(BatchActions.listSuccess((yield res.json())?.result));
}

function* getBatch(action: PayloadAction<string>) {
  const configuration = yield select(selectConfiguration);

  const res = yield fetch(configuration.API_BASE + `/api/batch/` + encodeURIComponent(action.payload), {
    headers: {
      Authorization: yield getCurrentJWT()
    }
  });

  const data = yield res.json();
  yield put(BatchActions.retrieveSuccess(data.result));
}

function* createBatchWithCallback(action) {
  const configuration = yield select(selectConfiguration);
  const { resolve } = action.payload;

  const res = yield fetch(configuration.API_BASE + `/api/batch`, {
    method: 'POST',
    headers: {
      Authorization: yield getCurrentJWT(),
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(action.payload)
  });
  const resultBody = yield res.json();

  yield put(BatchActions.createSuccess(resultBody));
  yield call(resolve, resultBody?.batchId);
}

function* updateBatch(action: PayloadAction<IBatchUpdate>) {
  const configuration = yield select(selectConfiguration);
  const { id } = action.payload;
  const res = yield fetch(configuration.API_BASE + `/api/batch/${id}`, {
    method: 'PUT',
    headers: {
      Authorization: yield getCurrentJWT(),
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(action.payload)
  });
  yield put(BatchActions.updateSuccess(res?.json()));
  yield put(BatchActions.retrieve(id));
}

function* deleteBatch(action: PayloadAction<string>) {
  const configuration = yield select(selectConfiguration);
  const id = action.payload;

  const res = yield fetch(configuration.API_BASE + `/api/batch/${id}`, {
    method: 'DELETE',
    headers: {
      Authorization: yield getCurrentJWT(),
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ id })
  });

  if (!res.ok) {
    yield put(BatchActions.deleteError());
    return;
  }
  yield put(BatchActions.deleteSuccess());
}

function* listTemplates(action: PayloadAction) {
  const configuration = yield select(selectConfiguration);

  const res = yield fetch(configuration.API_BASE + `/api/batch/templates`, {
    headers: {
      Authorization: yield getCurrentJWT()
    }
  });
  yield put(BatchActions.templateListSuccess(yield res.json()));
}

function* templateCSV(action: PayloadAction<IBatchDownloadTemplateCsv>) {
  const configuration = yield select(selectConfiguration);

  const { key, resolve } = action.payload;

  const res = yield fetch(configuration.API_BASE + `/api/batch/templates/${key}`, {
    headers: {
      Authorization: yield getCurrentJWT(),
      Accept: 'text/csv'
    }
  });

  yield call(resolve, yield res.text());
}

function* templateDetail(action: PayloadAction<string>) {
  const configuration = yield select(selectConfiguration);
  const key = action.payload;
  const res = yield fetch(configuration.API_BASE + `/api/batch/templates/${key}`, {
    headers: {
      Authorization: yield getCurrentJWT(),
      Accept: 'application/json'
    }
  });

  yield put(
    BatchActions.downloadTemplateSuccess({
      key: key,
      data: yield res.json()
    })
  );
}

function* executeBatch(action: PayloadAction<IBatchExecute>) {
  const configuration = yield select(selectConfiguration);
  const { id, desiredActivityState, treatmentOfErrorRows } = action.payload;

  const res = yield fetch(configuration.API_BASE + `/api/batch/${id}/execute`, {
    method: 'POST',
    headers: {
      Authorization: yield getCurrentJWT(),
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      desiredActivityState: desiredActivityState,
      treatmentOfErrorRows: treatmentOfErrorRows
    })
  });

  const data = yield res.json();

  if (data.code === 200) {
    yield put(BatchActions.executeSuccess(data.result));
    yield put(BatchActions.retrieve(id));
  } else {
    yield put(BatchActions.executeError(data.message ?? ''));
  }
}

function* batchSaga() {
  yield all([
    takeEvery(BatchActions.list, listBatches),
    takeLatest(BatchActions.retrieve, getBatch),
    takeEvery(BatchActions.update, updateBatch),
    takeEvery(BatchActions.delete, deleteBatch),
    takeEvery(BatchActions.deleteSuccess, listBatches),
    takeLatest(BatchActions.templateList, listTemplates),
    takeEvery(BatchActions.downloadTemplate, templateDetail),
    takeLatest(BatchActions.downloadTemplateCsv, templateCSV),
    takeLatest(BatchActions.createWithCallback, createBatchWithCallback),
    takeLatest(BatchActions.execute, executeBatch)
  ]);
}

export default batchSaga;
