export enum AlertSubjects {
  Map = 'map',
  Form = 'form',
  Photo = 'photo',
  Network = 'network',
  Cache = 'cache'
}

export enum AlertSeverity {
  Error = 'error',
  Info = 'info',
  Success = 'success',
  Warning = 'warning'
}
