export const SET_APP_MODE = 'SET_APP_MODE';
export const TOGGLE_PANEL = 'TOGGLE_PANEL';
export const OVERLAY_MENU_TOGGLE = 'OVERLAY_MENU_TOGGLE';
export const URL_CHANGE = 'URL_CHANGE';
export const SET_CURRENT_OPEN_SET = 'SET_CURRENT_OPEN_SET';

export const USER_CLICKED_RECORD = 'USER_CLICKED_RECORD';
export const USER_HOVERED_RECORD = 'USER_HOVERED_RECORD';
export const USER_TOUCHED_RECORD = 'USER_TOUCHED_RECORD';
export const RECORDSETS_TOGGLE_VIEW_FILTER = 'RECORDSETS_TOGGLE_VIEW_FILTER';

export const INIT_CACHE_RECORDSET = 'INIT_CACHE_RECORDSET';

export const AUTH_REQUEST_COMPLETE = 'AUTH_REQUEST_COMPLETE';
export const AUTH_REQUEST_ERROR = 'AUTH_REQUEST_ERROR';
export const AUTH_INITIALIZE_REQUEST = 'AUTH_INITIALIZE_REQUEST';
export const AUTH_INITIALIZE_COMPLETE = 'AUTH_INITIALIZE_COMPLETE';
export const AUTH_REINIT = 'AUTH_REINIT';
export const AUTH_SIGNIN_REQUEST = 'AUTH_SIGNIN_REQUEST';
export const AUTH_SIGNOUT_REQUEST = 'AUTH_SIGNOUT_REQUEST';
export const AUTH_SIGNOUT_COMPLETE = 'AUTH_SIGNOUT_COMPLETE';
export const AUTH_REFRESH_TOKEN = 'AUTH_REFRESH_TOKEN';
export const AUTH_UPDATE_TOKEN_STATE = 'AUTH_UPDATE_TOKEN_STATE';
export const AUTH_REFRESH_ROLES_REQUEST = 'AUTH_REFRESH_ROLES_REQUEST';
export const AUTH_SET_DISRUPTED = 'AUTH_SET_DISRUPTED';
export const AUTH_SET_RECOVERED_FROM_DISRUPTION = 'AUTH_SET_RECOVERED_FROM_DISRUPTION';
export const AUTH_CLEAR_ROLES = 'AUTH_CLEAR_ROLES';
export const AUTH_REFRESH_ROLES_ERROR = 'AUTH_REFRESH_ROLES_ERROR';
export const AUTH_REFRESH_ROLES_COMPLETE = 'AUTH_REFRESH_ROLES_COMPLETE';
export const AUTH_SAVE_CURRENT_TO_OFFLINE = 'AUTH_SAVE_CURRENT_TO_OFFLINE';
export const AUTH_MAKE_OFFLINE_USER_CURRENT = 'AUTH_MAKE_OFFLINE_USER_CURRENT';
export const AUTH_FORGET_OFFLINE_USER = 'AUTH_FORGET_OFFLINE_USER';
export const AUTH_OPEN_OFFLINE_USER_SELECTION_DIALOG = 'AUTH_OPEN_OFFLINE_USER_SELECTION_DIALOG';

export const REFETCH_SERVER_BOUNDARIES = 'REFETCH_SERVER_BOUNDARIES';

export const OPEN_NEW_RECORD_MENU = 'OPEN_NEW_RECORD_MENU';
export const CLOSE_NEW_RECORD_MENU = 'CLOSE_NEW_RECORD_MENU';
export const USERINFO_LOAD_COMPLETE = 'USERINFO_LOAD_COMPLETE';
export const USERINFO_CLEAR_REQUEST = 'USERINFO_CLEAR_REQUEST';
export const TOGGLE_QUICK_PAN_TO_RECORD = 'TOGGLE_QUICK_PAN_TO_RECORD';

export const ACTIVITY_GET_INITIAL_STATE_REQUEST = 'ACTIVITY_GET_INITIAL_STATE_REQUEST';
export const ACTIVITY_GET_INITIAL_STATE_SUCCESS = 'ACTIVITY_GET_INITIAL_STATE_SUCCESS';
export const ACTIVITY_GET_INITIAL_STATE_FAILURE = 'ACTIVITY_GET_INITIAL_STATE_FAILURE';

export const ACTIVITY_UPDATE_GEO_REQUEST = 'ACTIVITY_UPDATE_GEO_REQUEST';
export const ACTIVITY_UPDATE_GEO_SUCCESS = 'ACTIVITY_UPDATE_GEO_SUCCESS';
export const ACTIVITY_UPDATE_GEO_FAILURE = 'ACTIVITY_UPDATE_GEO_FAILURE';

export const ACTIVITY_BUILD_SCHEMA_FOR_FORM_REQUEST = 'ACTIVITY_BUILD_SCHEMA_FOR_FORM_REQUEST';
export const ACTIVITY_BUILD_SCHEMA_FOR_FORM_SUCCESS = 'ACTIVITY_BUILD_SCHEMA_FOR_FORM_SUCCESS';

export const ACTIVITY_ON_FORM_CHANGE_REQUEST = 'ACTIVITY_ON_FORM_CHANGE_REQUEST';
export const ACTIVITY_ON_FORM_CHANGE_SUCCESS = 'ACTIVITY_ON_FORM_CHANGE_SUCCESS';

export const ACTIVITY_SET_CURRENT_HASH_REQUEST = 'ACTIVITY_SET_CURRENT_HASH_REQUEST';
export const ACTIVITY_SET_CURRENT_HASH_SUCCESS = 'ACTIVITY_SET_CURRENT_HASH_SUCCESS';
export const ACTIVITY_SET_CURRENT_HASH_FAILURE = 'ACTIVITY_SET_CURRENT_HASH_REQUEST';

export const ACTIVITY_CHEM_TREATMENT_DETAILS_FORM_ON_CHANGE_REQUEST =
  'ACTIVITY_CHEM_TREATMENT_DETAILS_FORM_ON_CHANGE_REQUEST';
export const ACTIVITY_CHEM_TREATMENT_DETAILS_FORM_ON_CHANGE_SUCCESS =
  'ACTIVITY_CHEM_TREATMENT_DETAILS_FORM_ON_CHANGE_SUCCESS';

export const GET_API_DOC_REQUEST = 'GET_API_DOC_REQUEST';
export const GET_API_DOC_ONLINE = 'GET_API_DOC_ONLINE';
export const GET_API_DOC_SUCCESS = 'GET_API_DOC_SUCCESS';
export const GET_API_DOC_FAILURE = 'GET_API_DOC_FAILURE';

export const ACTIVITY_LINK_RECORD_REQUEST = 'ACTIVITY_LINK_RECORD_REQUEST';
export const ACTIVITY_LINK_RECORD_SUCCESS = 'ACTIVITY_LINK_RECORD_SUCCESS';
export const ACTIVITY_LINK_RECORD_FAILURE = 'ACTIVITY_LINK_RECORD_FAILURE';
export const ACTIVITY_PERSIST_REQUEST = 'ACTIVITY_PERSIST_REQUEST';
export const ACTIVITY_PERSIST_SUCCESS = 'ACTIVITY_PERSIST_SUCCESS';
export const ACTIVITY_PERSIST_FAILURE = 'ACTIVITY_PERSIST_FAILURE';

export const TOGGLE_CUSTOMIZE_LAYERS = 'TOGGLE_CUSTOMIZE_LAYERS';

export const ACTIVITY_SAVE_OFFLINE = 'ACTIVITY_SAVE_OFFLINE';
export const ACTIVITY_RESTORE_OFFLINE = 'ACTIVITY_RESTORE_OFFLINE';
export const ACTIVITY_OFFLINE_DELETE_ITEM = 'ACTIVITY_OFFLINE_DELETE_ITEM';
export const ACTIVITY_UPDATE_SYNC_STATE = 'ACTIVITY_UPDATE_SYNC_STATE';
export const ACTIVITY_OFFLINE_SYNC_DIALOG_SET_STATE = 'ACTIVITY_OFFLINE_SYNC_DIALOG_SET_STATE';

export const ACTIVITY_RUN_OFFLINE_SYNC = 'ACTIVITY_RUN_OFFLINE_SYNC';
export const ACTIVITY_RUN_OFFLINE_SYNC_COMPLETE = 'ACTIVITY_RUN_OFFLINE_SYNC_COMPLETE';

export const PAN_AND_ZOOM_TO_ACTIVITY = 'PAN_AND_ZOOM_TO_ACTIVITY';

export const ACTIVITY_DEBUG = 'ACTIVITY_DEBUG';

export const RECORDSET_ADD_FILTER = 'RECORDSET_ADD_FILTER';
export const RECORDSET_SET_SORT = 'RECORDSET_SET_SORT';
export const RECORDSET_CLEAR_FILTERS = 'RECORDSET_CLEAR_FILTERS';
export const INIT_SERVER_BOUNDARIES_GET = 'INIT_SERVER_BOUNDARIES_GET';

// Tabs
export const TABS_GET_INITIAL_STATE_REQUEST = 'TABS_GET_INITIAL_STATE_REQUEST';
export const TABS_GET_INITIAL_STATE_SUCCESS = 'TABS_GET_INITIAL_STATE_SUCCESS';
export const TABS_GET_INITIAL_STATE_FAILURE = 'TABS_GET_INITIAL_STATE_FAILURE';
export const TABS_SET_ACTIVE_TAB_REQUEST = 'TABS_SET_ACTIVE_TAB_REQUEST';
export const TABS_SET_ACTIVE_TAB_SUCCESS = 'TABS_SET_ACTIVE_TAB_SUCCESS';
export const TABS_SET_ACTIVE_TAB_FAILURE = 'TABS_SET_ACTIVE_TAB_FAILURE';

export const ACTIVITIES_GEOJSON_GET_REQUEST = 'ACTIVITIES_GEOJSON_GET_REQUEST';
export const ACTIVITIES_GEOJSON_GET_ONLINE = 'ACTIVITIES_GEOJSON_GET_ONLINE';
export const ACTIVITIES_GEOJSON_GET_OFFLINE = 'ACTIVITIES_GEOJSON_GET_OFFLINE';
export const ACTIVITIES_GEOJSON_GET_SUCCESS = 'ACTIVITIES_GEOJSON_GET_SUCCESS';

export const ACTIVITIES_GEOJSON_REFETCH_ONLINE = 'ACTIVITIES_GEOJSON_REFETCH_ONLINE';

export const ACTIVITY_ERRORS = 'ACTIVITY_ERRORS';

export const IAPP_GET_REQUEST = 'IAPP_GET_REQUEST';
export const IAPP_GET_LOCALDB_REQUEST = 'IAPP_GET_LOCALDB';
export const IAPP_GET_NETWORK_REQUEST = 'IAPP_GET_ONLINE';
export const IAPP_GET_SUCCESS = 'IAPP_GET_SUCCESS';
export const IAPP_GET_FAILURE = 'IAPP_GET_FAILURE';

export const IAPP_PAN_AND_ZOOM = 'IAPP_PAN_AND_ZOOM';

export const IAPP_GEOJSON_GET_REQUEST = 'IAPP_GEOJSON_GET_REQUEST';
export const IAPP_GEOJSON_GET_ONLINE = 'IAPP_GEOJSON_GET_ONLINE';
export const IAPP_GEOJSON_GET_OFFLINE = 'IAPP_GEOJSON_GET_OFFLINE';
export const IAPP_GEOJSON_GET_SUCCESS = 'IAPP_GEOJSON_GET_SUCCESS';

export const IAPP_INIT_LAYER_STATE_REQUEST = 'IAPP_INIT_LAYER_STATE_REQUEST';
export const IAPP_INIT_LAYER_STATE_SUCCESS = 'IAPP_INIT_LAYER_STATE_SUCCESS';

export const IAPP_TABLE_ROWS_GET_REQUEST = 'IAPP_TABLE_ROWS_GET_REQUEST';
export const IAPP_TABLE_ROWS_GET_ONLINE = 'IAPP_TABLE_ROWS_GET_ONLINE';
export const IAPP_TABLE_ROWS_GET_OFFLINE = 'IAPP_TABLE_ROWS_GET_OFFLINE';
export const IAPP_TABLE_ROWS_GET_SUCCESS = 'IAPP_TABLE_ROWS_GET_SUCCESS';
export const IAPP_TABLE_ROWS_GET_FAILURE = 'IAPP_TABLE_ROWS_GET_FAILURE';

export const IAPP_RECORDSET_ID_LIST_GET_SUCCESS = 'IAPP_RECORDSET_ID_LIST_GET_SUCCESS';

export const ACTIVITIES_TABLE_ROWS_GET_REQUEST = 'ACTIVITIES_TABLE_ROWS_GET_REQUEST';
export const ACTIVITIES_TABLE_ROWS_GET_ONLINE = 'ACTIVITIES_TABLE_ROWS_GET_ONLINE';
export const ACTIVITIES_TABLE_ROWS_GET_OFFLINE = 'ACTIVITIES_TABLE_ROWS_GET_OFFLINE';
export const ACTIVITIES_TABLE_ROWS_GET_SUCCESS = 'ACTIVITIES_TABLE_ROWS_GET_SUCCESS';
export const ACTIVITIES_TABLE_ROWS_GET_FAILURE = 'ACTIVITIES_TABLE_ROWS_GET_FAILURE';

export const MAP_INIT_REQUEST = 'MAP_INIT_REQUEST';
export const MAP_INIT_FOR_RECORDSET = 'MAP_INIT_FOR_RECORDSET';
export const MAP_INIT_SUCCESS = 'MAP_INIT_SUCCESS';

export const MAP_TOGGLE_ACCURACY = 'MAP_TOGGLE_ACCURACY';
export const MAP_TOGGLE_TRACKING = 'MAP_TOGGLE_TRACKING';
export const MAP_TOGGLE_TRACKING_ON = 'MAP_TOGGLE_TRACKING_ON';
export const MAP_TOGGLE_TRACKING_OFF = 'MAP_TOGGLE_TRACKING_OFF';
export const MAP_TOGGLE_PANNED = 'MAP_TOGGLE_PANNED';
export const MAP_TOGGLE_LEGENDS = 'MAP_TOGGLE_LEGENDS';
export const MAP_SET_COORDS = 'MAP_SET_COORDS';
export const LEAFLET_SET_WHOS_EDITING = 'LEAFLET_SET_WHOS_EDITING';
export const TOGGLE_BASIC_PICKER_LAYER = 'TOGGLE_BASIC_PICKER_LAYER';
export const RECORD_SET_TO_EXCEL_REQUEST = 'RECORD_SET_TO_EXCEL_REQUEST';
export const RECORD_SET_TO_EXCEL_SUCCESS = 'RECORD_SET_TO_EXCEL_SUCCESS';
export const RECORD_SET_TO_EXCEL_FAILURE = 'RECORD_SET_TO_EXCEL_FAILURE';
export const MAP_LABEL_EXTENT_FILTER_REQUEST = 'MAP_LABEL_EXTENT_FILTER_REQUEST';
export const MAP_LABEL_EXTENT_FILTER_SUCCESS = 'MAP_LABEL_EXTENT_FILTER_SUCCESS';
export const MAP_LABEL_EXTENT_FILTER_FAILURE = 'MAP_LABEL_EXTENT_FILTER_FAILURE';
export const IAPP_EXTENT_FILTER_REQUEST = 'IAPP_EXTENT_FILTER_REQUEST';
export const IAPP_EXTENT_FILTER_SUCCESS = 'IAPP_EXTENT_FILTER_SUCCESS';
export const IAPP_EXTENT_FILTER_FAILURE = 'IAPP_EXTENT_FILTER_FAILURE';
export const SET_TOO_MANY_LABELS_DIALOG = 'SET_TOO_MANY_LABELS_DIALOG';

export const TOGGLE_WMS_LAYER = 'TOGGLE_WMS_LAYER';
export const TOGGLE_LAYER_PICKER_OPEN = 'TOGGLE_LAYER_PICKER_OPEN';
export const TOGGLE_KML_LAYER = 'TOGGLE_KML_LAYER';
export const TOGGLE_DRAWN_LAYER = 'TOGGLE_DRAWN_LAYER';

export const MAP_ON_SHAPE_CREATE = 'MAP_ON_SHAPE_CREATE';
export const MAP_ON_SHAPE_UPDATE = 'MAP_ON_SHAPE_UPDATE';
export const MAIN_MAP_MOVE = 'MAIN_MAP_MOVE';
export const ACTIVITY_PAGE_MAP_EXTENT_TOGGLE = 'ACTIVITY_PAGE_MAP_EXTENT_TOGGLE';

export const MAP_DELETE_LAYER_AND_TABLE = 'MAP_DELETE_LAYER_AND_TABLE';

export const LAYER_STATE_UPDATE = 'LAYER_STATE_UPDATE';
export const FILTER_STATE_UPDATE = 'FILTER_STATE_UPDATE';
export const PAGE_OR_LIMIT_UPDATE = 'PAGE_OR_LIMIT_CHANGE';
export const SORT_COLUMN_STATE_UPDATE = 'SORT_COLUMN_STATE_UPDATE';

export const IAPP_GET_IDS_FOR_RECORDSET_REQUEST = 'IAPP_GET_IDS_FOR_RECORDSET_REQUEST';
export const IAPP_GET_IDS_FOR_RECORDSET_ONLINE = 'IAPP_GET_IDS_FOR_RECORDSET_ONLINE';
export const IAPP_GET_IDS_FOR_RECORDSET_OFFLINE = 'IAPP_GET_IDS_FOR_RECORDSET_OFFLINE';
export const IAPP_GET_IDS_FOR_RECORDSET_SUCCESS = 'IAPP_GET_IDS_FOR_RECORDSET_SUCCESS';

export const ACTIVITIES_GET_IDS_FOR_RECORDSET_REQUEST = 'ACTIVITIES_GET_IDS_FOR_RECORDSET_REQUEST';
export const ACTIVITIES_GET_IDS_FOR_RECORDSET_ONLINE = 'ACTIVITIES_GET_IDS_FOR_RECORDSET_ONLINE';
export const ACTIVITIES_GET_IDS_FOR_RECORDSET_SUCCESS = 'ACTIVITIES_GET_IDS_FOR_RECORDSET_SUCCESS';
export const FILTERS_PREPPED_FOR_VECTOR_ENDPOINT = 'FILTERS_PREPPED_FOR_VECTOR_ENDPOINT';
export const FILTER_PREP_FOR_VECTOR_ENDPOINT = 'FILTER_PREP_FOR_VECTOR_ENDPOINT';

export const MAP_TOGGLE_GEOJSON_CACHE = 'MAP_TOGGLE_GEOJSON_CACHE';

export const FILTER_FEATURE_SET_WITH_IDS = 'FILTER_FEATURE_SET_WITH_IDS';
export const MAP_MODE_SET = 'MAP_MODE_SET';

export const DRAW_CUSTOM_LAYER = 'DRAW_CUSTOM_LAYER';
export const CUSTOM_LAYER_DRAWN = 'CUSTOM_LAYER_DRAWN';
export const REMOVE_CLIENT_BOUNDARY = 'REMOVE_CLIENT_BOUNDARY';
export const REMOVE_SERVER_BOUNDARY = 'REMOVE_SERVER_BOUNDARY';

export const CSV_LINK_CLICKED = 'CSV_LINK_CLICKED';

export const EXPORT_CONFIG_LOAD_REQUEST = 'EXPORT_CONFIG_LOAD_REQUEST';
export const EXPORT_CONFIG_LOAD_ERROR = 'EXPORT_CONFIG_LOAD_ERROR';
export const EXPORT_CONFIG_LOAD_SUCCESS = 'EXPORT_CONFIG_LOAD_SUCCESS';

export const TRAINING_VIDEOS_LIST_REQUEST = 'TRAINING_VIDEOS_LIST_REQUEST';
export const TRAINING_VIDEOS_LIST_REQUEST_COMPLETE = 'TRAINING_VIDEOS_LIST_REQUEST_COMPLETE';

export const EMAIL_SETTINGS_UPDATE = 'EMAIL_SETTINGS_REQUEST';
export const EMAIL_SETTINGS_UPDATE_SUCCESS = 'EMAIL_SETTINGS_UPDATE_SUCCESS';
export const EMAIL_SETTINGS_UPDATE_FAILURE = 'EMAIL_SETTINGS_UPDATE_FAILURE';

export const EMAIL_SETTINGS_RETRIEVE_REQUEST = 'EMAIL_SETTINGS_RETRIEVE_REQUEST';
export const EMAIL_SETTINGS_RETRIEVE_REQUEST_SUCCESS = 'EMAIL_SETTINGS_RETRIEVE_REQUEST_SUCCESS';
export const EMAIL_SETTINGS_RETRIEVE_REQUEST_FAILURE = 'EMAIL_SETTINGS_RETRIEVE_REQUEST_FAILURE';

export const EMAIL_TEMPLATES_UPDATE = 'EMAIL_TEMPLATES_REQUEST';
export const EMAIL_TEMPLATES_UPDATE_SUCCESS = 'EMAIL_TEMPLATES_UPDATE_SUCCESS';
export const EMAIL_TEMPLATES_UPDATE_FAILURE = 'EMAIL_TEMPLATES_UPDATE_FAILURE';
export const EMAIL_TEMPLATES_SET_ACTIVE = 'EMAIL_TEMPLATES_SET_ACTIVE';

export const EMAIL_TEMPLATES_RETRIEVE_REQUEST = 'EMAIL_TEMPLATES_RETRIEVE_REQUEST';
export const EMAIL_TEMPLATES_RETRIEVE_REQUEST_SUCCESS = 'EMAIL_TEMPLATES_RETRIEVE_REQUEST_SUCCESS';
export const EMAIL_TEMPLATES_RETRIEVE_REQUEST_FAILURE = 'EMAIL_TEMPLATES_RETRIEVE_REQUEST_FAILURE';

export const CRASH_HANDLE_GLOBAL_ERROR = 'CRASH_HANDLE_GLOBAL_ERROR';
